<template>
  <div class="file-uploader-remark">
    <h6 class="title">หมายเหตุ</h6>
    <div class="input-description">รองรับไฟล์ *.jpg, *.png, *.pdf, *.doc, *.docx, *.xls, *.xlsx และรองรับขนาดไฟล์ไม่เกิน {{ getMaximumFileSize() }}MB ต่อไฟล์</div>
  </div>
</template>

<script>
export default {
  props: {
    maximumSize: {
      type: String,
      default: '50'
    }
  },
  methods: {
    getMaximumFileSize() {
      return this.maximumSize;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title { font-weight: 500;}
</style>
