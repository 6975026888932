<template>
  <div 
    class="
      duck-form 
      add--project--form
    "
  >
    <form
      @submit.prevent="saveForm"
    >
      <fieldset 
        class="
          form-block
          add--project--block
        "
      >
        <div 
          class="form-container"
        >
          <SmallTitle 
            title="เอกสารประกอบ"
            class="bottom-line"
          />
          <MultipleFileUploader 
            section="project"
            type='document' 
            :parentId="projectId" 
          />
          
          <SmallTitle 
            title="เอกสาร Survey (ถ้ามี)"
            class="
              mt-20 
              bottom-line
            "
          />
          <MultipleFileUploader 
            section="project"
            type='survey' 
            :parentId="projectId" 
          />

          <FileUploaderRemark />

          <ProjectSubmitBlock text="กลับไปยังหน้ารายละเอียด" />
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>

// Load components
import SmallTitle from '@/components/common/SmallTitle.vue';
import MultipleFileUploader from '@/components/file/MultipleFileUploader.vue';
import FileUploaderRemark   from '@/components/file/FileUploaderRemark.vue';
import ProjectSubmitBlock from '@/components/project/form/button/ProjectSubmitBlock.vue';

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  /**
   * Registration components
   */
  components: {
    SmallTitle,
    FileUploaderRemark,
    MultipleFileUploader,
    ProjectSubmitBlock,
  },

  /**
   * Initial this component when created
   */
  created() {
    this.projectId = this.$route.params.id;
    
    // set page title
    this.$store.commit('setPageTitle', 'Edit Project');
    this.$store.commit('setHeadTitle', 'Edit Project : Project File');

    // hide main loader
    this.$store.commit('setLoaderShow', false);
  },

  /**
   * Methods
   */
  methods: {
    /**
     * saveForm
     *    redirect to view project only
     */
    saveForm() {
      this.$router.push('/project/view/' + this.projectId);
    },
  }
}
</script>